import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FeatureHub } from 'featurehub-react-sdk';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <FeatureHub url="https://features.platform-nonprod.imaginelearning.com" apiKey="117d6c9f-1add-4c39-a0e5-4b53052476e5/ZLsY5dxKqhcqKgSCF9RwbZTgKvoGNu*133W8HNNDerS5ffUjzd1">
      <App />
    </FeatureHub>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
